import React from "react";

const About = () => {
    return (
        <h2 style={{ textAlign: "center", color: "orange" }}>
            <br />
            Приложение МЕНЕДЖЕР ЗАДАЧ - это инструмент для управления проектами.
            <br />
            Поможет планировать, расставлять приоритеты по задачам и контролировать соки
            выполнения.
            <br />
            Работу можно сделать проще и приятнее, если использовать МЕНЕДЖЕР ЗАДАЧ.
        </h2>
    );
};

export default About;
